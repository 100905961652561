import React, { useState, useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import { commerce } from "./lib/commerce";
import Products from "./components/Products/Products";
import Navbar from "./components/Navbar/Navbar";
import Cart from "./components/Cart/Cart";
import Checkout from "./components/CheckoutForm/Checkout/Checkout";
import ProductView from "./components/ProductView/ProductView";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Confirm_checkout from "./components/confirm_checkout/Confirm_checkout";
import SelectTicket from "./components/select_ticket/SelectTicket";
import AddressForm from "./components/CheckoutForm/AddressForm";
import FinalForm from "./components/CheckoutForm/FinalForm";
import Ticket from "./components/Qr_ticket/Ticket";
import BookingList from "./components/Products/bookings";
import BookingTicket from "./components/Products/detailed_booking_ticket_view";
import PrivacyPolicy from "./components/Privacypolicy/Privacypolicy";
const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({});
  const [order, setOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await commerce.products.list();
      setProducts(data);
    };

    const fetchCart = async () => {
      try {
        const cart = await commerce.cart.retrieve();
        setCart(cart);
      } catch (error) {
        console.error('Error fetching cart:', error);
        // Handle the error, e.g., show an error message to the user
      }
    };
    

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    window.addEventListener("resize", handleResize);

    // fetchProducts();
    // fetchCart();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileOpen]);

  const handleAddToCart = async (productId, quantity) => {
    const item = await commerce.cart.add(productId, quantity);
    setCart(item.cart);
  };

  const handleUpdateCartQty = async (lineItemId, quantity) => {
    const response = await commerce.cart.update(lineItemId, { quantity });
    setCart(response.cart);
  };

  const handleRemoveFromCart = async (lineItemId) => {
    const response = await commerce.cart.remove(lineItemId);
    setCart(response.cart);
  };

  const handleEmptyCart = async () => {
    const response = await commerce.cart.empty();
    setCart(response.cart);
  };

  const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
    try {
      const incomingOrder = await commerce.checkout.capture(
        checkoutTokenId,
        newOrder
      );
      setOrder(incomingOrder);
      refreshCart();
    } catch (error) {
      setErrorMessage(error.data.error.message);
    }
  };

  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();
    setCart(newCart);
  };

  return (
    <div>
      <Router>
        <div style={{ display: "flex" }}>
          <CssBaseline />
          <Navbar
            totalItems={cart.total_items}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Switch>
            <Route exact path="/">
              <Products
                products={products}
                onAddToCart={handleAddToCart}
                handleUpdateCartQty={handleUpdateCartQty}
              />
            </Route>
            <Route exact path="/cart">
              <Cart
                cart={cart}
                onUpdateCartQty={handleUpdateCartQty}
                onRemoveFromCart={handleRemoveFromCart}
                onEmptyCart={handleEmptyCart}
              />
            </Route>
            <Route path="/checkout/:id" exact>
              <Checkout
                cart={cart}
                order={order}
                onCaptureCheckout={handleCaptureCheckout}
                error={errorMessage}
              />
            </Route>
            <Route path="/product-view/:id" exact>
              <ProductView />
            </Route>
            <Route path="/confirm-checkout/:id/:price/:tType" exact>
              <Confirm_checkout />
            </Route>
            <Route path="/address-form/:id/:quantity/:amount/:ticket_type/:handling_fee" exact>
              <AddressForm />
            </Route>
            <Route path="/final-form/:id/:quantity/:amount/:ticket_type/:handling_fee" exact>
              <FinalForm />
            </Route>
            <Route path="/event-qr/:bookingID/:id" exact>
              <Ticket />
            </Route>
            <Route path="/select-ticket/:id" exact>
              <SelectTicket />
            </Route>
            <Route path="/privacypolicy/" exact>
              <PrivacyPolicy />
            </Route>
            <Route path="/booking" exact>
              <BookingList />
            </Route>
            <Route path="/booking/:booking_id" exact>
              < BookingTicket/ >
            </Route>
          </Switch>
        </div>
      </Router>
      {/* Render the footer only if window width is greater than 768px (adjust the value as needed) */}
      {windowWidth > 768 && <Footer />}
    </div>
  );
};

export default App;